import "../styles/components/_headertop.scss";

export const HeaderTop = () => {
  return (
    <>
      <div className="container-fluid header-top bg-pink">
        <div className="container title">
          <h2>Gratis verzending vanaf €30</h2>
        </div>
      </div>
    </>
  );
};
